
import { Component, Vue } from 'vue-property-decorator';
import { getPost, likePost, updatePost } from '@/api/post';
import { addViewCount } from '@/api/hits';

@Component({
  components: {
  },
})

export default class extends Vue {
  mounted() {
    this.getPost();
  }

  private post: any = {
    uid: '',
    title: '',
    content: '',
    writer: '',
    viewCount: 0,
    noticeStatus: false,
  };

  private postLike: any = {
    idx: 0,
    postUid: '',
    boardUid: '',
    userUid: '',
    parenUid: '',
  }

  private loading = true;

  private active = false;

  private getPost() {
    this.loading = true;
    getPost(this.$route.params.postUid).then((res) => {
      this.post = res.data;
      console.log(this.post);
      this.active = res.data.myLikeFlag;
      this.loading = false;
    }).catch(() => {
      this.$message.error('게시글을 불러오는데 실패했습니다.');
      this.$router.push({ name: 'Notice' });
    }).then(() => {
      addViewCount({ postUid: this.$route.params.postUid });
    });
  }

  private goBack() {
    this.$router.go(-1);
  }

  private handleClick() {
    this.likePost();
  }

  // 도움이 되었어요 api
  private likePost() {
    this.postLike.postUid = this.$route.params.postUid;
    const isLiked = this.active;

    likePost(this.$route.params.postUid, this.postLike).then((res) => {
      if (res.status === 200) {
        if (isLiked) {
          this.post.likeCount -= 1;
        } else {
          this.post.likeCount += 1;
        }
        this.active = !this.active;
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  private updatePost() {
    this.$router.push({ name: 'PostUpdate', params: { postUid: this.postLike } });
  }
}
